@tailwind base;

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat-Black"),
    url(/fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: local("Montserrat-ExtraBold"),
    url(/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}

@tailwind components;

body {
  font-family: "Montserrat";
  font-style: normal;
  
}

.font-Montserrat {
  font-family: "Montserrat";
  font-style: normal;
}

.font-Montserrat-ExtraBold {
  font-family: "Montserrat-ExtraBold";
  font-style: normal;
}

.Montserrat-Black {
  font-family: "Montserrat-Black";
  font-style: normal;
}

.intro-video {
  max-width: 560px;
  height: 315px;
  width: 100%;
}

@media (max-width: 360px) {
  .intro-video {
    height: 180px;
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  .intro-video {
    height: 280px;
  }
}

.home-gradient {
   background: transparent linear-gradient(180deg, #000 10%, #000 100%) 10%
    0% no-repeat padding-box;
  opacity: 1;
  height: 950px;
}

.contactus-gradient {
 background: transparent linear-gradient(180deg, #53c162 0%, #edcc15 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  height: 3000px;
}

.force-height {
  min-height: calc(100vh - 318px);
}
ax {
  text-decoration: none;
  color: #008CBA;
}
.token-gradient {
  background: transparent linear-gradient(180deg, #12072b 0%, #169c00 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  height: 6000px;
}
/* for Opera,webkit chrome browsers */
input::-webkit-input-placeholder { 
    color: #000;
}
/*firefox 19+ versions*/
input::-moz-placeholder {
    color: #000;
}
/*IE  versions*/
input::-ms-placeholder {
    color: #000;
}
/*Latest modern browsers */

input::placeholder {
    color: #000;
}

.input-bg {
  background-color: rgba(255, 255, 255,0.8);
}

.card-bg {
    background: transparent linear-gradient(180deg, #ff008e 0%, #0831fc 100%) 0%
    0% no-repeat padding-box;
}

.footer-bg {
  background: transparent linear-gradient(180deg, #000 10%, #000 100%) 10%
    0% no-repeat padding-box;
  opacity: 1;

}

.top-noisy {
  top: -200px;
  left: -50px;
  width: 1400px;
  max-width: initial;
}

.second-noisy {
  top: 1100px;
  left: -700px;
  width: 1400px;
  max-width: initial;
}

.third-noisy {
  transform: rotate(180deg);
  top: 1300px;
  right: -700px;
  width: 1400px;
  max-width: initial;
}

.fourth-noisy {
  top: 3000px;
  left: -50px;
  width: 1400px;
  max-width: initial;
}

.bottom-noisy {
  top: 2800px;
  left: -700px;
  width: 1400px;
  max-width: initial;
}

.fifth-noisy {
  transform: rotate(180deg);
  top: 4500px;
  right: -200px;
  width: 1400px;
  max-width: initial;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.line {
  fill: none;
  stroke: #fff;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

#toggler {
  color: #D01961;
  font-size: 24px;
  background-color: white;
  margin: 12px;
  padding: 4px 32px;
  border-radius: 5px;
}

#toggler :active {
  color: white;
  font-size: 24px;
  background-color: #D01961;
  margin: 12px;
  padding: 4px 32px;
  border-radius: 5px;
}

#controls {
  margin: 32px
}
@tailwind utilities;
